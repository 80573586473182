import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  createStatus: 'idle',
  error: null,
  data: null,
  routeManagementList: null,
  totalCount: 10,
  routeItem: null,
  assignedRetailersList: null,
  assignedSellersList: null,
};

const routeManagementSlice = createSlice({
  name: 'routesManagementList',
  initialState,
  reducers: {
    createRoute: state => {
      state.isLoading = true;
    },
    createRouteSuccess: (state, action) => {
      state.createStatus = 'success';
      state.isLoading = false;
      const list = state.data ? state.data : [];
      state.data = [action.payload, ...list].map(item => {
        return {
          ...item,
        };
      });
      state.error = null;
    },
    createRouteFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
    },

    restRouteCreateStatus: state => {
      state.createStatus = 'idle';
    },

    loadRoutesManagement: state => state,
    loadRoutesManagementSuccess: (state, action) => {
      state.routeManagementList = action.payload?.routes;
      state.isLoading = false;
      state.totalCount = action.payload?.totalCount;
    },
    loadRoutesManagementFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },


    createSalesOffice: state => {
      state.isLoading = true;
    },
    createSalesOfficeSuccess: state => {
      state.createStatus = 'success';
      state.isLoading = false;
      state.error = null;
    },
    createSalesOfficeFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
    },
    restSalesOfficeCreateStatus: state => {
      state.createStatus = 'idle';
    },
    resetState: () => {
      return { ...initialState };
    },


    loadRouteManagementData: state => {
      state.isLoading = true;
      return state;
    },
    loadRouteManagementDataSuccess: (state, action) => {
      state.routeItem = action.payload.route;
      state.isLoading = false;
    },

    loadRouteManagementDataFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },


    loadAssignedSellersList: state => {
      state.isLoading = true;
      return state;
    },
    loadAssignedSellersListSuccess: (state, action) => {
      state.assignedSellersList = action?.payload?.agents;
      state.totalCount = action?.payload?.totalCount;
      state.isLoading = false;
    },
    loadAssignedSellersListFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },


    loadAssignedRetailersList: state => {
      state.isLoading = true;
      return state;
    },
    loadAssignedRetailersListSuccess: (state, action) => {
      state.assignedRetailersList = action?.payload?.retailers;
      state.totalCount = action?.payload?.totalCount;
      state.isLoading = false;
      },
    loadAssignedRetailersListFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
     },
  },
});

const { actions, reducer } = routeManagementSlice;

export const {
  createRoute,
  createRouteSuccess,
  createRouteFailed,
  restRouteCreateStatus,

  loadRoutesManagement,
  loadRoutesManagementSuccess,
  loadRoutesManagementFailed,

  createSalesOffice,
  createSalesOfficeSuccess,
  createSalesOfficeFailed,
  restSalesOfficeCreateStatus,

  loadRouteManagementData,
  loadRouteManagementDataSuccess,
  loadRouteManagementDataFailed,

  loadAssignedSellersList,
  loadAssignedSellersListSuccess,
  loadAssignedSellersListFailed,

  loadAssignedRetailersList,
  loadAssignedRetailersListSuccess,
  loadAssignedRetailersListFailed,
} = actions;

export default reducer;
