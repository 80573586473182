import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  createStatus: 'idle',
  error: null,
  data: null,
  routeManagementList: null,
  totalCount: 10,
};

const routeSlice = createSlice({
  name: 'routeList',
  initialState,
  reducers: {
    loadRoutes: state => {
      state.isLoading = true;
      return state;
    },
    loadRoutesSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    loadRoutesFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = routeSlice;

export const { loadRoutes, loadRoutesSuccess, loadRoutesFailed } = actions;

export default reducer;
