import { Layout } from 'antd';
import BaseSidebar from './sidebar';
import BaseHeader from './header';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import useIdleTimeout from 'hooks/useIdleTimeout';
import { Modal, Typography } from 'antd';
import BaseButton from 'components/button';
import { useDispatch } from 'react-redux';
import { loadLogOut } from 'pages/login/store/slice';

const { Paragraph } = Typography;

const BaseLayOut = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  const handleIdle = () => {
    setOpenModal(true);
  };
  const handleRequestLogout = () => {
    dispatch(loadLogOut());
  };
  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 600, callback: handleRequestLogout });

  const handleCancel = () => {
    setOpenModal(false);
    idleTimer.reset();
  };

  const handleLogout = () => {
    handleRequestLogout();
    setOpenModal(false);
  };
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}>
      <Modal
        open={openModal}
        closable={false}
        destroyOnClose={true}
        footer={[
          <BaseButton key="back" type="default" onClick={handleCancel} bg="transparent" text="Stay signed in" />,
          <BaseButton
            key="submit"
            type="primary"
            bg="#ff4d4f"
            text="Sign out now"
            danger={true}
            onClick={handleLogout}
          />,
        ]}>
        <Paragraph>Your session is about to expire. You will be automatically signed out.</Paragraph>
        <Paragraph>Do you want to stay signed in?</Paragraph>
      </Modal>
      <BaseHeader />
      <Layout>
        <BaseSidebar />
        <Layout>
          <Outlet />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default BaseLayOut;
