import { all } from 'redux-saga/effects';
import { watchLoginPageRequests } from 'pages/login/store/saga';
import { watchRouteListRequests } from './slices/routeSlice/saga';
import { watchGovernmentListRequests } from './slices/government/saga';
import { watchSellersListPageRequests } from 'pages/sellers/store/saga';
import { watchRoutesManagementListRequests } from 'pages/routes/store/saga';

export default function* rootSaga() {
  yield all([
    watchLoginPageRequests(),
    watchRouteListRequests(),
    watchGovernmentListRequests(),
    watchSellersListPageRequests(),
    watchRoutesManagementListRequests(),
  ]);
}
