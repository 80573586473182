import { sellersUrl } from '../utils';
import qs from 'qs';

export const getRoutesRequest = ({ page, limit, areaId, ...filters }) => {
  return {
    url: `${sellersUrl}routes`,
    method: 'GET',
    params: {
      filter: { areaId, ...filters },
      limit,
      page
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, {
        skipNulls: true,
      });
    },
  };
};

