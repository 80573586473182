/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import { loadRoutes, loadRoutesSuccess, loadRoutesFailed } from './slice';

import { getRoutesRequest } from 'api/requestCreators/routes';

import { showError } from 'redux/utility-sagas';

function* handleLoadRouteListRequests(action) {
  try {
    const filters = { ...action?.payload };

    const {
      data: { data },
    } = yield call(execute, getRoutesRequest(filters));

    yield put(loadRoutesSuccess(data));
  } catch (error) {
    yield put(loadRoutesFailed(error.response?.data));
    yield showError(error);
  }
}

export function* watchRouteListRequests() {
  yield takeLatest(loadRoutes.type, handleLoadRouteListRequests);
}
