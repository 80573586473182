/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import {
  createRoute,
  createRouteSuccess,
  createRouteFailed,
  loadRoutesManagement,
  loadRoutesManagementSuccess,
  loadRoutesManagementFailed,
  createSalesOffice,
  createSalesOfficeSuccess,
  createSalesOfficeFailed,
  loadRouteManagementData,
  loadRouteManagementDataSuccess,
  loadRouteManagementDataFailed,

  loadAssignedSellersList,
  loadAssignedSellersListSuccess,
  loadAssignedSellersListFailed,

  loadAssignedRetailersList,
  loadAssignedRetailersListSuccess,
  loadAssignedRetailersListFailed,
} from './slice';

import {
  getCreateRouteRequest,
  getRoutesManagementRequest,
  getCreateSalesOfficeRequest,
  getRouteManagementRequest,
  getAssignedSellersRequest,
  getAssignedRetailersRequest
} from 'api/requestCreators/routes-management';
import { showError } from 'redux/utility-sagas';

function* handleLoadRouteManagementListRequests(action) {
  try {
    const filters = { ...action?.payload };

    const {
      data: { data },
    } = yield call(execute, getRoutesManagementRequest(filters));

    yield put(loadRoutesManagementSuccess(data));
  } catch (error) {
    yield put(loadRoutesManagementFailed(error.response?.data));
    yield showError(error);
  }
}

function* handleCreateRouteRequests(action) {
  try {
    const item = {
      ...action.payload,
    };

    const {
      data: { data },
    } = yield call(execute, getCreateRouteRequest(item));

    yield put(createRouteSuccess(data));
  } catch (error) {
    yield put(createRouteFailed(error.response?.data));
    yield showError(error);
  }
}

function* handleCreateSalesOfficeRequests(action) {
  try {
    const item = {
      ...action.payload,
    };

    const {
      data: { data },
    } = yield call(execute, getCreateSalesOfficeRequest(item));

    yield put(createSalesOfficeSuccess(data));
  } catch (error) {
    yield put(createSalesOfficeFailed(error.response?.data));
    yield showError(error);
  }
}

function* handleLoadRouteManagementRequests(action) {
  const { payload: id } = action;
  try {
    const {
      data: {
        data 
      },
    } = yield call(execute, getRouteManagementRequest(id));
     yield put(loadRouteManagementDataSuccess(data));
  } catch (error) {
    yield put(loadRouteManagementDataFailed(error.response?.data));
    yield showError(error);
  }
}

function* handleLoadAssignedSellersListRequests(action) {
  const filters = action?.payload;
  try {
    const {
      data: { data },
    } = yield call(execute, getAssignedSellersRequest(filters));

    yield put(loadAssignedSellersListSuccess(data));
  } catch (error) {
    yield put(loadAssignedSellersListFailed(error.response?.data));
    yield showError(error);
  }
}

function* handleLoadAssignedRetailersListRequests(action) {
  const filters = action?.payload;
  try {
    const {
      data: { data },
    } = yield call(execute, getAssignedRetailersRequest(filters));

    yield put(loadAssignedRetailersListSuccess(data));
  } catch (error) {
    yield put(loadAssignedRetailersListFailed(error.response?.data));
    yield showError(error);
  }
}

export function* watchRoutesManagementListRequests() {
  yield takeLatest(createRoute.type, handleCreateRouteRequests);
  yield takeLatest(loadRoutesManagement.type, handleLoadRouteManagementListRequests);
  yield takeLatest(createSalesOffice.type, handleCreateSalesOfficeRequests);
  yield takeLatest(loadRouteManagementData.type, handleLoadRouteManagementRequests);
  yield takeLatest(loadAssignedSellersList.type, handleLoadAssignedSellersListRequests);
  yield takeLatest(loadAssignedRetailersList.type, handleLoadAssignedRetailersListRequests);
}
